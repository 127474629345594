const theme = {
  colors: {
    background: '#E7ECEF',
    headerBackground: '#adbfca',
    headerBorder: '#588B8B',
    timelineBackground: '#adbfca',
    timelineText: '#3a2512',
    timelineTextHover: '#005d97',
    bodyBackground: 'white',
    bodyBorder: '#F28F3B',
    headerLink: '#003352',
    headerLinkHover: '#005d97',
    nameColor: '#3f6780',
    contactLink: '#a15517',
    contactLinkHover: '#c5a167',
    contactLabel: 'black',
    projectTitle: 'black',
    projectLink: '',
    text: 'black',
  },
  altColors: {
    background: '#121212',
    headerBackground: '#2b2b2b',
    headerBorder: 'black',
    timelineBackground: '#2b2b2b',
    timelineText: '#29d9b3',
    timelineTextHover: 'gray',
    bodyBackground: '#242424',
    bodyBorder: 'red',
    headerLink: '#29d9b3',
    headerLinkHover: 'gray',
    nameColor: '#f7f7f7',
    contactLink: '#29d9b3',
    contactLinkHover: 'gray',
    contactLabel: '#f7f7f7',
    projectTitle: '#f7f7f7',
    projectLink: '#29d9b3',
    text: '#BDBDBD',
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xlg: '1200px',
  },
};

// 588B8B steel Teal
// FFD5C2 unbleached silk
// F28F3B cadmium orange
// C8553D cedar

export default theme;
